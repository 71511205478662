import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollToDirective } from "./scroll-to.directive";

@NgModule({
  declarations: [ScrollToDirective],
  imports: [
    CommonModule
  ],
  exports: [ScrollToDirective]
})
export class ScrollToGlobalModule { }

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'div[app-social]',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class SocialComponent implements OnInit {
  @Input() showIconClass: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}

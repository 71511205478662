import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HeaderModule } from './shared/components/header/header.module';

//Header directive
import { NavbarBgScrollDirective } from "./shared/directives/navbar-bg-scroll.directive";
import { HeaderSetActive } from "./shared/directives/header-set-active.directive";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

//Hammer
import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser'
import { HammerModule } from '@angular/platform-browser'
import * as Hammer from 'hammerjs'
import { HttpClientModule } from '@angular/common/http';


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): any {
    return new Hammer(element, {
      swipe: { direction: Hammer.DIRECTION_ALL },
      pan: { enable: false },
    })
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarBgScrollDirective,
    HeaderSetActive
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    HttpClientModule,
    BrowserAnimationsModule, HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    })
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

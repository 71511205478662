<div class="container-xxl navbar-bg">
  <div class="row">
    <div class="col-24">
      <nav class="navbar navbar-expand-md w-100">
        <a class="navbar-brand" appScrollTo [sectionID]="'top'">
          <svg id="NY_LOGO" data-name="NY LOGO" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 1864.5 209.7" xml:space="preserve">
            <g>
              <polygon class="navbar-brand-svg" points="153.9,64 34.9,119.6 34.9,7.4 4.9,7.4 4.9,200 34.9,200 34.9,150.8 153.9,95.3 153.9,200 183.9,200    183.9,7.4 153.9,7.4  "/>
              <path class="navbar-brand-svg" d="M813.5,61.8c1-16.4,27.6-28.8,55.8-28.8s55.9,12.8,55.9,29.7h31.2c0-35.8-39.3-59-87-59s-87,23.2-87,59v16.8   l145.4,67.8c-2.7,15.6-29.5,27.2-56.7,27.2c-28.8,0-57-12.8-57-29.7h-31.2c0,35.8,40.4,59,88.2,59s88.2-23.2,88.2-59v-15.1   L813.5,61.8L813.5,61.8z"/>
              <polygon class="navbar-brand-svg" points="563.6,7.4 489.7,181.5 415.8,7.4 383.3,7.4 465,200 514.4,200 596.2,7.4  "/>
              <path class="navbar-brand-svg" d="M415,200L333.3,7.4h-49.4L202.1,200h32.6l6.6-15.6L353.6,132l28.9,68L415,200L415,200z M308.6,26l33.9,80   l-84.6,39.5L308.6,26L308.6,26z"/>
              <path class="navbar-brand-svg" d="M777.3,200L695.5,7.4h-49.4L564.4,200H597l6.6-15.6L715.9,132l28.9,68L777.3,200L777.3,200z M670.8,26l33.9,80   l-84.6,39.5L670.8,26z"/>
            </g>
            <g>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1048.6,65.2h20.3v117.2h65.9V200h-86.2L1048.6,65.2L1048.6,65.2z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1144.7,65.2h19.5v22.2h-19.5V65.2L1144.7,65.2z M1144.7,100.4h19.5V200h-19.5V100.4z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1190.5,117h-15v-16.6h15v-5.1c0-21.9,8.5-30.1,30.9-30.1h11.8v16.6h-11c-10.2,0-12.1,3.3-12.1,12.9v5.7h23.2   V117H1210v83h-19.5V117z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1235.2,149.8v-0.2c0-31.2,21.4-51.9,50.1-51.9c31.8,0,50.3,24.4,49.1,58.1v0.1h-78.4v0.1   c1.8,18.4,13.1,30.7,30.4,30.7c14.8,0,22.9-5.6,26.7-15.1h19.9c-5.4,18.1-21.3,31.2-47.2,31.2   C1253.9,202.7,1235.2,179.9,1235.2,149.8L1235.2,149.8z M1256.2,140L1256.2,140l57.5,0.1V140c-0.9-15.5-11.6-26.8-28.6-26.8   C1269.7,113.2,1258.6,121.7,1256.2,140L1256.2,140z"/>
            </g>
            <g>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1397.8,122.9h13.3l39.6,60.7h0.1l-0.1-17.5V123h11.2V200h-13.3l-39.6-60.5h-0.1l0.1,17.5V200h-11.2V122.9z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1471.8,171.3v-0.1c0-17.8,12.2-29.7,28.6-29.7c18.2,0,28.8,13.9,28.1,33.2v0h-44.8v0   c1,10.5,7.5,17.6,17.4,17.6c8.5,0,13.1-3.2,15.3-8.6h11.4c-3.1,10.4-12.2,17.8-26.9,17.8C1482.5,201.5,1471.8,188.5,1471.8,171.3   L1471.8,171.3z M1483.8,165.7L1483.8,165.7l32.9,0v0c-0.5-8.9-6.6-15.3-16.3-15.3C1491.5,150.4,1485.2,155.2,1483.8,165.7z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1528.9,143.1h11.5l13.2,45h0.1l13.5-45h11.9l14.1,45h0.1l13.4-45h10.7l-17.6,57h-12.5l-14.5-45h-0.1l-13.7,45   h-12.6L1528.9,143.1L1528.9,143.1z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1670.7,171.7l-30.6-48.7h13.2l19.2,31.5l4.1,6.6h0.1l23.1-38.1h13.2l-30.6,48.7V200h-11.6L1670.7,171.7   L1670.7,171.7z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1704.5,171.5v-0.1c0-17.2,11-29.9,28.8-29.9s28.8,12.6,28.8,29.9v0.1c0,17.3-10.9,30-28.8,30   S1704.5,188.8,1704.5,171.5z M1750.3,171.5v-0.1c0-12.2-5.7-20.7-17-20.7s-17,8.5-17,20.7v0.1c0,12.2,5.8,20.8,17,20.8   S1750.3,183.7,1750.3,171.5z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1770.6,143.1h11.2v10.1c3.3-6.5,9.4-10.7,18.4-10.7h1v10.9h-3c-10.4,0-16.4,4.7-16.4,16.9V200h-11.2   L1770.6,143.1L1770.6,143.1z"/>
              <path class="navbar-brand-svg navbar-brand-svg--black" d="M1806.8,122.9h11.2V167l27.2-24h13.7l-31,26.5l32.9,30.4h-15.9l-26.9-25.1V200h-11.2V122.9L1806.8,122.9z"/>
            </g>
          </svg>
        </a>
        <div appBlockScroll class="toggler">
          <div class="navbar-toggler" (click)="morphToX()" [ngClass]="{'navbar-x': !isMenuCollapsed}">
            <svg id="mennu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <rect class="square" fill="#fff" width="8" height="8" />
              <rect class="square" fill="#fff" y="12" width="8" height="8" />
              <rect class="square" fill="#fff" y="24" width="8" height="8" />
              <rect class="square" fill="#fff" x="12" width="8" height="8" />
              <rect class="square" fill="#fff" x="12" y="12" width="8" height="8" />
              <rect class="square" fill="#fff" x="12" y="24" width="8" height="8" />
              <rect class="square" fill="#fff" x="24" width="8" height="8" />
              <rect class="square" fill="#fff" x="24" y="12" width="8" height="8" />
              <rect class="square" fill="#fff" x="24" y="24" width="8" height="8" />
              <path class="close" fill="#fff"
                d="M31.41.66a2,2,0,0,0-2.82,0L16,13.21,3.43.66A2,2,0,0,0,.58,3.45l0,0L13.18,16,.61,28.57a2,2,0,0,0-.12,2.74l.07.08a2,2,0,0,0,2.83.05l0-.05L16,18.84,28.59,31.39a2,2,0,0,0,2.85-2.79l0,0L18.84,16,31.41,3.48A2,2,0,0,0,31.49.74a.6.6,0,0,0-.08-.08"
                transform="translate(0)" />
            </svg>
          </div>
        </div>

        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
          <div class="navbar-collapse-container" id="nav-collapse">
            <ul class="navbar-nav">
              <ng-container *ngFor="let item of navLinks; let last = last">
                <li *ngIf="!last" class="nav-item" appBlockScroll appScrollTo [sectionID]="item.id" [id]="item.id">
                  <a class="nav-link" (click)="isMenuCollapsed = true" [innerHTML]="item.link"></a>
                </li>
              </ng-container>
            </ul>
            <div class="navbar-socials" app-social [showIconClass]="showIconClass"></div>
            <div class="nav-chicago nav-chicago--red">
              <a class="stretched-link" [href]="navLinks[5].action" [innerHTML]="navLinks[5].link" target="_blank"
                rel="noopener"></a>
              <svg id="right_arrow" class="nav-arrow" data-name="right arrow" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 30">
                <path fill="#dc002e"
                  d="M.27,29.75a.86.86,0,0,0,.6.25.84.84,0,0,0,.6-.25L15.75,15.61a.84.84,0,0,0,0-1.18h0L1.48.27A.86.86,0,0,0,.27.23.85.85,0,0,0,.22,1.42h0l0,0L13.93,15,.27,28.55a.84.84,0,0,0,0,1.19Z"
                  transform="translate(0 0)" />
              </svg>
            </div>
          </div>
        </div>

      </nav>
    </div>

  </div>
</div>
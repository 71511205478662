import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions, PreloadAllModules } from '@angular/router';
//Resolvers
import { HomepageContentResolver } from "./shared/resolvers/homepage-content-resolver.service";

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'top',
  preloadingStrategy: PreloadAllModules,
  // anchorScrolling: 'enabled',
  // scrollOffset: [0, 100],
}
const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./homepage/homepage.module').then((m) => m.HomepageModule),
    resolve: { homepageData: HomepageContentResolver }
  },
  {
    path: 'cookie-notice',
    loadChildren: () =>
      import('./cookie-notice/cookie-notice.module').then((m) => m.CookieNoticeModule)
  },
  {
    path: '**',
    redirectTo: '',
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

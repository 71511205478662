import { Directive, Renderer2, HostListener } from '@angular/core';
import { gsap } from "gsap";
import { Router } from '@angular/router';
@Directive({
  selector: "[appBlockScroll]"
})
export class blockScrollDirective {
  constructor(private renderer: Renderer2, private router: Router) { }

  @HostListener('click') onClick() {
    if (typeof document.getElementsByClassName("navbar-x")[0] !== "undefined") {
      this.renderer.setStyle(document.body, "overflow", "hidden");
      document.getElementsByTagName("html")[0].style.overflow = 'hidden';
    } else {
      //Close
      this.renderer.removeStyle(document.body, "overflow");
      document.getElementsByTagName("html")[0].removeAttribute("style");
      gsap.set('.nav-chicago', { clearProps: 'backgroundColor' })
      gsap.to('.square', { duration: 0.5, scale: 1 })
      gsap.to('.close', { duration: 0.5, scale: 0, autoAlpha: 0 })
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (this.router.url === '/') {
      if (document.body.offsetWidth > 768 && document.getElementsByClassName('modal__ny')[0] === undefined) {
        this.renderer.removeStyle(document.body, "overflow");
        document.getElementsByTagName("html")[0].removeAttribute("style");
        gsap.set('.nav-chicago', { clearProps: 'backgroundColor' })
        gsap.to('.square', { duration: 0.5, scale: 1, ease: 'power4.easeOut' })
        gsap.to('.close', { duration: 0.5, scale: 0, autoAlpha: 0 })
        if (document.getElementsByClassName("navbar-x")[0]) {
          this.renderer.removeClass(
            document.getElementsByClassName("navbar-x")[0], 'navbar-x')
        }

      }
    }
  }
}
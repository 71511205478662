<div class="socials-container">
  <a href="https://www.instagram.com/havaslifeny/" target="_blank" rel="noopener" name="instagram">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.57 22.57">
      <path fill="#FFFFFF"
        d="M6.15,1.8A4.35,4.35,0,0,0,1.8,6.15V16.42a4.68,4.68,0,0,0,4.35,4.35H16.42a4.35,4.35,0,0,0,4.35-4.35V6.15A4.68,4.68,0,0,0,16.42,1.8Zm0-1.8H16.42a6.47,6.47,0,0,1,6.15,6.15V16.42a6.16,6.16,0,0,1-6.15,6.15H6.15A6.46,6.46,0,0,1,0,16.42V6.15A6.15,6.15,0,0,1,6.15,0Zm9.47,6a1.38,1.38,0,1,0,2.76,0A1.38,1.38,0,1,0,15.62,6Zm-.9,5.33a3.44,3.44,0,1,0-3.44,3.44A3.44,3.44,0,0,0,14.72,11.28Zm1.8,0a5.24,5.24,0,1,1-5.24-5.23A5.23,5.23,0,0,1,16.52,11.28Z" />
    </svg>
  </a>
  <a href="https://www.linkedin.com/company/havas-life-newyork/" target="_blank" rel="noopener" name="linkedin">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.92 25.92">
      <path fill="#fff"
        d="M25.92,16.63v8.91a.39.39,0,0,1-.39.38H20.46a.38.38,0,0,1-.4-.38v-7.9a6.78,6.78,0,0,0-.41-2.71,2.1,2.1,0,0,0-2.1-1.22A2.33,2.33,0,0,0,15.3,14.8a5.58,5.58,0,0,0-.5,2.7v8a.4.4,0,0,1-.4.38H9.47a.4.4,0,0,1-.39-.38V9.32a.4.4,0,0,1,.39-.38h4.72a.4.4,0,0,1,.39.38v1.15A4.71,4.71,0,0,1,16,9.56a8.09,8.09,0,0,1,3.26-.62,6.93,6.93,0,0,1,4.82,1.45,6,6,0,0,1,1.74,3.87c0,.35.08.74.1,1.14s0,.82,0,1.23"
        transform="translate(0 0)" />
      <path fill="#fff"
        d="M6,9.29V25.54a.37.37,0,0,1-.36.37H.36A.37.37,0,0,1,0,25.54V9.29a.38.38,0,0,1,.36-.38H5.64A.38.38,0,0,1,6,9.29"
        transform="translate(0 0)" />
      <path fill="#fff"
        d="M6.92,3.41a3.39,3.39,0,0,1-1,2.39l0,0a3.51,3.51,0,0,1-4.9,0A3.38,3.38,0,0,1,1,1.05L1,1a3.51,3.51,0,0,1,4.9,0,3.37,3.37,0,0,1,1,2.41"
        transform="translate(0 0)" />
    </svg>
  </a>
  <a href="https://www.facebook.com/havaslifeNY" class="{{showIconClass}}" target="_blank" rel="noopener" name="facebook">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28.01">
      <path fill="#fff"
        d="M19.34,28V17.37c0-.2,0-.2.2-.2h3.25c.2,0,.19,0,.21-.19l.27-2.07.24-1.84v-.15H19.35c0-.06,0-.1,0-.14,0-.93,0-1.86,0-2.78a2.72,2.72,0,0,1,.09-.69,1.36,1.36,0,0,1,1.18-1.05,6.69,6.69,0,0,1,.89-.08h2c.2,0,.2,0,.2-.19V4.56c0-.19,0-.19-.19-.21a25.71,25.71,0,0,0-3.4-.13,5.25,5.25,0,0,0-3.37,1.2A4.81,4.81,0,0,0,15.13,8.1a7.58,7.58,0,0,0-.19,1.78v3H11.46c-.13,0-.17,0-.17.18V17c0,.15.05.18.19.18h3.27c.19,0,.19,0,.19.18V28H1.6A1.53,1.53,0,0,1,0,26.7a2.62,2.62,0,0,1,0-.28V1.57A1.51,1.51,0,0,1,1.46,0h25A1.53,1.53,0,0,1,28,1.49V26.36a1.53,1.53,0,0,1-.9,1.49,1.71,1.71,0,0,1-.67.14H19.34"
        transform="translate(0 0)" />
    </svg>
  </a>
  <!-- <a href="https://twitter.com/HavasLifeNY" class="{{showIconClass}}" target="_blank" rel="noopener" name="twitter">
    <svg id="twitter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 21">
      <path fill="#fff"
        d="M26,2.49a10.85,10.85,0,0,1-3.06.83A5.32,5.32,0,0,0,25.28.39,10.59,10.59,0,0,1,21.9,1.67,5.35,5.35,0,0,0,12.67,5.3a4.93,4.93,0,0,0,.13,1.21A15.16,15.16,0,0,1,1.81,1,5.29,5.29,0,0,0,3.46,8.05a5.47,5.47,0,0,1-2.41-.66v.06a5.3,5.3,0,0,0,4.27,5.2,5.3,5.3,0,0,1-2.41.09,5.33,5.33,0,0,0,5,3.68,10.68,10.68,0,0,1-6.62,2.27A10.86,10.86,0,0,1,0,18.62,15.18,15.18,0,0,0,8.18,21,15,15,0,0,0,23.35,6.13V5.92c0-.23,0-.46,0-.69A11,11,0,0,0,26,2.49"
        transform="translate(0 0)" />
    </svg>
  </a> -->
</div>
import { Directive, HostListener, OnInit, AfterViewInit, Renderer2, Input, OnDestroy, OnChanges } from "@angular/core";
import { Router } from '@angular/router';

@Directive({
  selector: "[appheaderSetActive]"
})

export class HeaderSetActive implements OnInit, AfterViewInit {
  sections: any = document.getElementsByTagName('section');
  offsetSectionTop: number[] = [];
  sectionIdentifier: string[] = [
    "hero",
    "hero-second",
    "about-us",
    "people-in-motion",
    "covid-life",
    "driven-people",
    "moving-work",
    "work-with-us"
  ];

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.router.url === '/') {
        this.calcOffsetTop();
        // console.log("Sections top " + this.offsetSectionTop)
        this.scrollingOnSections()
      }
    }, 1000);
  }

  ngOnInit() {

  }

  constructor(private renderer: Renderer2, private router: Router) {

  }


  @HostListener('window:resize', ['$event']) onResize(event) {
    // event.stopImmediatePropagation()
    setTimeout(() => {
      if (this.router.url === '/') {
        this.calcOffsetTop();
      }
    }, 1000);
  }

  @HostListener('window:scroll', []) onScroll(event: any) {
    if (this.router.url === '/') {
      this.scrollingOnSections()
    }
  }

  calcOffsetTop() {
    this.offsetSectionTop = []
    for (let i = 0; i < this.sections.length; i++) {
      this.offsetSectionTop.push(this.sections[i].offsetTop - document.getElementById('header').offsetHeight)
    }
  }

  scrollingOnSections() {
    for (let x = 0; x < this.offsetSectionTop.length; x++) {
      //First position
      if (x === 0) {
        if (window.scrollY < this.offsetSectionTop[x + 1]) {
          // console.log(this.sectionIdentifier[x])
          this.calcOffsetTop();
        }
      }
      //In the middle
      else if (x != this.offsetSectionTop.length - 1) {
        if (window.scrollY < this.offsetSectionTop[x + 1] && window.scrollY >= this.offsetSectionTop[x]) {
          this.calcOffsetTop();
          // console.log(this.sectionIdentifier[x])
          if (this.sectionIdentifier[x] !== 'hero' &&
            this.sectionIdentifier[x] !== 'hero-second') {
            // console.log("entre")
            if(this.sectionIdentifier[x] == 'people-in-motion'){
              //console.log('you are people and motion');
              this.removeActive(this.sectionIdentifier[x]);
              this.setActive('about-us');
            }else{
              this.removeActive(this.sectionIdentifier[x]);
              this.setActive(this.sectionIdentifier[x]);
            }

          } else {
            this.removeAllActive()
          }
        }
      }
      //Last value 
      else {
        if (window.scrollY >= this.offsetSectionTop[x]) {
          if (this.sectionIdentifier[x] !== 'hero' &&
            this.sectionIdentifier[x] !== 'hero-second' &&
            this.sectionIdentifier[x] !== 'people-in-motion') {
            // console.log("entre")
            this.removeActive(this.sectionIdentifier[x]);
            this.setActive(this.sectionIdentifier[x]);
          } else {
            this.removeAllActive()
          }
        }
      }
    }
  }

  setActive(id: string) {
    this.renderer.addClass(document.getElementById(id), 'active')
  }

  removeActive(id: string) {
    for (let i = 0; i < this.sections.length; i++) {
      if (this.sections[i].id != id) {
        let cleanId = this.sections[i].id.toString().substring('section-'.length);
        if (cleanId !== 'hero' && cleanId !== 'hero-second' && cleanId != 'people-in-motion' && cleanId !== id) {
          this.renderer.removeClass(document.getElementById(cleanId), 'active');
        }
      }
    }
  }
  removeAllActive() {
    for (let i = 0; i < this.sections.length; i++) {
      let cleanId = this.sections[i].id.toString().substring('section-'.length);
      if (cleanId !== 'hero' && cleanId !== 'hero-second' && cleanId != 'people-in-motion') {
        this.renderer.removeClass(document.getElementById(cleanId), 'active');
      }
    }
  }
}
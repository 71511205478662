import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { HomepageContentService } from '../services/homepage-content.service'

@Injectable({
  providedIn: 'root'
})
export class HomepageContentResolver implements Resolve<[]> {
  constructor(private homepageData: HomepageContentService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<[]> | Promise<[]> | [] {
    return this.homepageData.getHomepageData();
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { gsap, Power4 } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
@Component({
  selector: 'header[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:resize)': 'onWindowResize($event)',
    id: 'header'
  },
})
export class HeaderComponent implements OnInit {
  public isMenuCollapsed = true;
  showIconClass: string = "showIconClass";
  xPath: string = "M24.54.44a1.6,1.6,0,0,0-2.21,0L12.51,9.87,2.68.44a1.6,1.6,0,0,0-2.2,0,1.46,1.46,0,0,0,0,2.07l0,0L10.3,12,.48,21.42a1.46,1.46,0,0,0-.1,2.06l.06.06a1.61,1.61,0,0,0,2.21,0l0,0,9.83-9.43,9.82,9.43a1.61,1.61,0,0,0,2.21,0,1.46,1.46,0,0,0,0-2.07l0,0L14.72,12l9.82-9.43A1.45,1.45,0,0,0,24.6.5L24.54.44";
  navLinks: any[] = [
    {
      link: "ABOUT US",
      action: "",
      id: "about-us"
    },
    {
      link: "COVID LIFE",
      action: "",
      id: "covid-life"
    },
    {
      link: "DRIVEN PEOPLE",
      action: "",
      id: "driven-people"
    },
    {
      link: "MOVING WORK",
      action: "",
      id: "moving-work"
    },
    {
      link: "WORK WITH US",
      action: "",
      id: "work-with-us"
    },
    {
      link: "CHICAGO",
      action: "https://havaslifechicago.com/",
      id: ""
    }
  ]
  constructor(
    public router: Router

  ) {
    gsap.registerPlugin(CSSPlugin);
  }

  ngOnInit(): void {
    if (this.router.url === '/') {
      gsap.set(".square", { transformOrigin: 'center center' })
      gsap.set(".close", { scale: 0, autoAlpha: 0, transformOrigin: 'center center' })
    }

  }

  morphToX() {
    if (this.router.url === '/') {
      document.getElementById('nav-collapse').setAttribute('style', `height: ${window.innerHeight - 57}px`)
      document.getElementById('nav-collapse').style.height = `${window.innerHeight - 57}px`
      this.isMenuCollapsed = !this.isMenuCollapsed;
      if (!this.isMenuCollapsed) {
        gsap.set(['.nav-link', '.navbar-socials svg'], { y: 30, opacity: 0 })
        gsap.to('.square', { duration: 0.5, scale: 0, ease: 'power4.easeOut' })
        gsap.to('.close', { duration: 0.5, scale: 1, autoAlpha: 1 })
        gsap.to(['.nav-link', '.navbar-socials svg'], { y: 0, opacity: 1, duration: .2, stagger: .1, ease: 'power4.easeOut' });
        gsap.to('.nav-chicago', { duration: 0.5, css: { backgroundColor: '#fff' }, ease: 'power4.easeOut' });
      }
    }

  }

  onWindowResize() {
    if (document.body.offsetWidth > 768) {
      this.isMenuCollapsed = true;
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
//NGB bootstrap
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap'
//Global module
import { SocialModule } from "../social/social.module";
//Block scroll directive
import { blockScrollDirective } from "../../directives/block-scroll.directive";
import { ScrollToGlobalModule } from "../../directives/scroll-to-global.module";
@NgModule({
  declarations: [HeaderComponent, blockScrollDirective],
  imports: [
    CommonModule,
    NgbCollapseModule,
    SocialModule,
    ScrollToGlobalModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule { }

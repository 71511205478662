import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: "[appNarvarBgScroll]"
})
export class NavbarBgScrollDirective {
  sY: number = 0;
  constructor(private elRef: ElementRef, private renderer: Renderer2) {
  }
  @HostListener('window:resize', ['$event']) onResize(event) {

    if (document.body.offsetWidth <= 768) {
      this.renderer.removeClass(this.elRef.nativeElement, 'header__bg')
    } else {
      if (this.sY >= document.querySelector("header").offsetHeight) {
        this.renderer.addClass(this.elRef.nativeElement, 'header__bg')
      }
    }
  }

  @HostListener('window:scroll', []) onScroll(event: any) {
    this.sY = window.scrollY;
    if (document.body.offsetWidth >= 768) {
      if (window.scrollY >= document.querySelector("header").offsetHeight) {
        this.renderer.addClass(this.elRef.nativeElement, 'header__bg')
      } else {
        this.renderer.removeClass(this.elRef.nativeElement, 'header__bg')
      }
    }
  }
}
import { Directive, HostListener, Input } from '@angular/core';
import { gsap, Power3 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
@Directive({
  selector: "[appScrollTo]"
})
export class ScrollToDirective {
  @Input() sectionID: string = "";
  constructor(private router: Router) {
    gsap.registerPlugin(ScrollToPlugin)
  }
  @HostListener('click') onClick() {
    let section = 0;
    if (document.body.offsetWidth >= 768) {
      if (this.sectionID.includes('top')) {
        if (this.router.url === '/') {
          window.location.reload();
        } else {
          this.router.navigate(['/']);
        }
      } else {
        if (document.getElementsByClassName("header__bg")[0] !== undefined) {
          section = document.getElementById(`section-${this.sectionID}`).offsetTop - document.getElementById("header").offsetHeight;
        } else {
          section = document.getElementById(`section-${this.sectionID}`).offsetTop;
        }
      }
    } else {
      if (this.sectionID.includes('top')) {
        if (this.router.url === '/') {
          window.location.reload();
        } else {
          this.router.navigate(['/']);
        }
      } else {
        section = document.getElementById(`section-${this.sectionID}`).offsetTop - 72
      }
    }
    gsap.to(window, { duration: 1, scrollTo: section, ease: "power3.inOut" })
  }
}